@import "_utility-bk";

// layout

#wrapper {
  width: 100%;
  overflow: hidden; }

.inner {
  width: 1120px;
  margin-left: auto;
  margin-right: auto; }

.sp, .tb, .tb_sp {
  display: none; }

.f12 {
  font-size: 12px; }

.f16 {
  font-size: 16px; }

.f18 {
  font-size: 18px; }

.bold {
  font-weight: bold; }

.in_bl {
  display: inline-block; }

.v_m {
  vertical-align: middle;
  display: table-cell; }

.font01 {
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif; }

.bl-hover {
  cursor: pointer;
  img {
    opacity: 0.8; } }

// header

#header_in:after {
  content: " ";
  display: block;
  clear: both; }

header {
  color: #fff;
  a {
    color: #fff; } }

#top-head {
  position: absolute;
  width: 100%;
  z-index: 10010;
  height: 130px;
  #header_in {
    max-width: 1820px;
    margin: 0 auto;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    position: relative; } }

header {
  h1 {
    display: table;
    height: 130px;
    a {
      display: table-cell;
      vertical-align: middle; }
    max-width: 273px; }
  #h_menu {
    position: absolute;
    right: 0;
    top: 0;
    line-height: 1.2;
    width: 480px;
    li {
      float: left;
      text-align: center;
      position: relative;
      &:after {
        position: absolute;
        content: '';
        top: 20px;
        right: 0;
        background: rgba(255, 255, 255, 0.3);
        width: 1px;
        height: 90px; }
      &:nth-child(1) {
        width: 240px;
        font-size: 23px; }
      &:nth-child(2), &:nth-child(3) {
        width: 120px; }
      a, .tel {
        display: table;
        height: 130px;
        position: relative;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0);
        -webkit-transition: border .2s;
        transition: border .2s; }
      &:nth-child(1) .icon {
        background: url("../img/common/icon03.png") no-repeat;
        width: 24px;
        height: 25px;
        margin-right: 10px;
        display: inline-block; }
      &:nth-child(2) .icon {
        background: url("../img/common/icon01.png") no-repeat;
        width: 26px;
        height: 21px;
        display: block;
        margin: 0 auto 15px; }
      &:nth-child(3) .icon {
        background: url("../img/common/icon02.png") no-repeat;
        width: 20px;
        height: 25px;
        display: block;
        margin: 0 auto 10px; } }
    a:hover {
      color: #fff;
      border-bottom: 1px solid #C9FB01; } } }

#globalnav {
  li a:hover {
    color: #fff;
    border-bottom: 1px solid #C9FB01; }
  position: absolute;
  right: 490px;
  top: 0; }

// globalnav

#gnav_btn {
  display: none; }

#globalnav li {
  float: left;
  font-size: 16px;
  padding: 0 15px;
  a {
    display: block;
    height: 130px;
    line-height: 130px;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    -webkit-transition: border .2s;
    transition: border .2s; } }

// Fixed

#top-head.fixed {
  &:after {
    content: " ";
    display: block;
    clear: both; }
  position: fixed;
  height: 80px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  background-color: #111;
  #header_in {
    border-bottom: none; }
  h1 {
    height: 80px; }
  #h_menu li {
    &:after {
      top: 10px;
      height: 60px; }
    &:nth-child(2) .icon {
      margin-bottom: 10px; }
    &:nth-child(3) .icon {
      margin-bottom: 5px; }
    a, .tel {
      height: 80px; } }
  #globalnav li a {
    height: 80px;
    line-height: 80px; } }

// footer

footer:after, #footer_box01 .inner:after, #footer_box02 .inner:after {
  content: " ";
  display: block;
  clear: both; }

footer {
  text-align: center;
  line-height: 1.2; }

#footer_box01 {
  color: #fff;
  background: url("../img/common/footer_bg01.png") repeat-x center;
  background-size: cover;
  a {
    color: #fff; }
  .inner {
    padding: 60px 0; }
  ul {
    display: flex;
    justify-content: space-between; } }

#footer_box02 {
  background-color: #1B1B25;
  padding: 40px 0;
  color: #888;
  a {
    color: #888; }
  #f_logo {
    margin-bottom: 20px;
    img {
      max-width: 230px; } }
  #f_contact {
    font-size: 13px;
    margin-bottom: 45px; }
  #f_menu {
    font-size: 12px;
    margin-bottom: 5px;
    li {
      display: inline-block;
      &:first-child {
        padding-right: 5px;
        margin-right: 5px;
        border-right: 1px solid #888; } } }
  small {
    font-size: 11px; } }

footer a:hover {
  text-decoration: underline; }


// タブレット用
// screens smaller than 1820----------------------------------------------------------
@media only screen and (min-width: 641px) and (max-width: 1819px) {
  // header
  #top-head h1 {
    padding-left: 1%; } }

// 新規追加（960-1599）
@media only screen and (min-width: 960px) and (max-width: 1599px) {
  header {
    #h_menu {
      li {
        // &:nth-child(1)
        //   font-size: 16px
        //   width: 160px
        //   .icon
        //     width: 16px
        //     height: 17px
 } } }        //     background-size: 16px
  #top-head {
    height: 100px;
    position: fixed;
    h1 {
      height: 100px; }
    #h_menu {
      width: 560px;
      display: flex;
      padding-top: 10px;
      li {
        float: none;
        &::after {
          top: 5px;
          height: 30px; }
        &:nth-child(2), &:nth-child(3) {
          width: 160px;
          .v_m {
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center; }
          .icon {
            margin: 0;
            margin-right: 20px; } }
        a, .tel {
          height: 40px; } } } }
  #globalnav {
    top: 50px;
    right: 0;
    width: 660px;
    ul {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap; }
    li {
      float: none;
      padding: 0 10px;
      a {
        height: 40px;
        line-height: 40px;
        font-size: 14px; } } }
  #top-head.fixed {
    height: 100px;
    h1 {
      height: 100px; }
    #h_menu li {
      &:after {
        top: 5px;
        height: 30px; }
      &:nth-child(2), &:nth-child(3) {
        .icon {
          margin-bottom: 0; } }
      a, .tel {
        height: 40px; } }
    #globalnav li a {
      height: 40px;
      line-height: 40px; } } }




// screens smaller than 1600----------------------------------------------------------
@media only screen and (min-width: 641px) and (max-width: 959px) {
  // // header
  #top-head {
    height: 80px;
    position: fixed;
    background-color: #111;
    #header_in {
      position: relative;
      border-bottom: none; }
    h1 {
      height: 80px; } }
  header #h_menu {
    right: 10vw; }
  #top-head #h_menu li {
    &:after {
      top: 10px;
      height: 60px; }
    &:nth-child(2) .icon {
      margin-bottom: 10px; }
    &:nth-child(3) .icon {
      margin-bottom: 5px; }
    a, .tel {
      height: 80px; } }
  // // globalnav
  #globalnav {
    li {
      padding: 0 10px;
      a {
        height: 80px;
        line-height: 80px; } }
    width: 100%;
    position: static;
    z-index: 1; }
  // // globalnav
  #gnav_btn {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 10vw;
    height: 80px;
    text-align: center;
    a {
      display: inline-block;
      position: relative;
      height: 80px;
      text-indent: -9999px;
      width: 100%; } }
  #panel-btn-icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 4px;
    margin: -2px 0 0 -15px;
    background: #fff;
    transition: .2s;
    &:before, &:after {
      display: block;
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 30px;
      height: 4px;
      background: #fff;
      transition: .3s; }
    &:before {
      margin-top: -12px; }
    &:after {
      margin-top: 8px; } }
  #gnav_btn a .close {
    background: transparent;
    &:before, &:after {
      margin-top: 0; }
    &:before {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg); }
    &:after {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg); } }
  #globalnav_in {
    display: block;
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 80px;
    left: 0;
    z-index: 10; }
  #globalnav {
    ul {
      display: none; }
    li {
      float: none;
      border-bottom: 1px solid #333;
      padding: 0 0 0 8%;
      background: #222;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 12px;
        top: -webkit-calc(50% - 4px);
        top: calc(50% - 4px);
        left: 4%;
        width: 6px;
        height: 6px;
        border-top: solid 1px #fff;
        border-right: solid 1px #fff;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg); }
      a {
        padding: 16px 0;
        height: auto;
        line-height: 1.4; } } }
  #top-head.fixed #globalnav li a {
    padding: 16px 0;
    height: auto;
    line-height: 1.4; }
  #globalnav li a:hover, #top-head.fixed #globalnav li a:hover {
    border: none; } }

// // screens smaller than 1120----------------------------------------------------------
@media only screen and (min-width: 641px) and (max-width: 1119px) {
  // // layout
  .inner {
    width: 95%; }
  .pc {
    display: none; }
  .tb, .tb_sp {
    display: inherit; }
  // // footer
  #footer_box01 {
    background: #1B1B25 none;
    .inner {
      padding: 50px 0 0; }
    ul {
      display: inherit;
      max-width: 700px;
      margin: 0 auto; }
    li {
      display: inline-block;
      padding: 0 10px;
      margin-bottom: 10px; } } }

// // screens smaller than 880----------------------------------------------------------
@media only screen and (min-width: 641px) and (max-width: 879px) {
  // // header
  header #h_menu {
    width: auto;
    right: 80px;
    li {
      float: none; } }
  #top-head {
    #h_menu li {
      &:before {
        position: absolute;
        content: '';
        top: 20px;
        left: 0;
        background: rgba(255, 255, 255, 0.3);
        width: 1px;
        height: 40px; }
      &:after {
        top: 20px;
        height: 40px; } }
    &.fixed #h_menu li:after {
      top: 20px;
      height: 40px; } }
  header #h_menu {
    li {
      &:nth-child(1), &:nth-child(3) {
        display: none; } }
    .text {
      display: none; } }
  #top-head {
    #h_menu li:nth-child(2) .icon, &.fixed #h_menu li:nth-child(2) .icon {
      margin-bottom: 0; }
    #h_menu li:nth-child(2) {
      width: 80px; } }
  header #h_menu {
    li a, a:hover {
      border-bottom: none; } }
  #globalnav li a:hover {
    border-bottom: none; }
  // // globalnav
  #gnav_btn {
    width: 80px; } }



// スマートフォン用
// screens smaller than 640----------------------------------------------------------
@media only screen and (max-width: 640px) {
  // // layout
  .inner {
    width: 94%; }
  .sp {
    display: inherit; }
  .pc, .pc_tb, .tb {
    display: none; }
  .tb_sp {
    display: inherit; }
  .page-link {
    padding-top: 0;
    margin-top: 0; }
  // // header
  #top-head {
    height: 80px;
    position: fixed;
    background-color: #111;
    #header_in {
      position: relative;
      border-bottom: none; }
    h1 {
      height: 80px;
      padding-left: 1%;
      width: 60%; } }
  header #h_menu {
    width: auto;
    right: 80px;
    li {
      float: none; } }
  #top-head {
    #h_menu li {
      a {
        height: 80px; }
      &:before {
        position: absolute;
        content: '';
        top: 20px;
        left: 0;
        background: rgba(255, 255, 255, 0.3);
        width: 1px;
        height: 40px; }
      &:after {
        top: 20px;
        height: 40px; } }
    &.fixed #h_menu li:after {
      top: 20px;
      height: 40px; } }
  header #h_menu {
    li {
      &:nth-child(1), &:nth-child(3) {
        display: none; } }
    .text {
      display: none; }
    li:nth-child(2) .icon {
      margin-bottom: 0; } }
  #top-head {
    &.fixed #h_menu li:nth-child(2) .icon {
      margin-bottom: 0; }
    #h_menu li:nth-child(2) {
      width: 80px; } }
  header #h_menu {
    li a, a:hover {
      border-bottom: none; } }
  #globalnav {
    li a:hover {
      border-bottom: none; }
    width: 100%;
    position: static;
    z-index: 1; }
  // // globalnav
  #gnav_btn {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 80px;
    text-align: center;
    a {
      display: inline-block;
      position: relative;
      height: 80px;
      text-indent: -9999px;
      width: 100%; } }
  #panel-btn-icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 4px;
    margin: -2px 0 0 -15px;
    background: #fff;
    transition: .2s;
    &:before, &:after {
      display: block;
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 30px;
      height: 4px;
      background: #fff;
      transition: .3s; }
    &:before {
      margin-top: -12px; }
    &:after {
      margin-top: 8px; } }
  #gnav_btn a .close {
    background: transparent;
    &:before, &:after {
      margin-top: 0; }
    &:before {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg); }
    &:after {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg); } }
  #globalnav_in {
    display: block;
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 80px;
    left: 0;
    z-index: 10; }
  #globalnav {
    ul {
      display: none; }
    li {
      float: none;
      border-bottom: 1px solid #333;
      padding: 0 0 0 8%;
      background: #222;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 12px;
        top: -webkit-calc(50% - 4px);
        top: calc(50% - 4px);
        left: 4%;
        width: 6px;
        height: 6px;
        border-top: solid 1px #fff;
        border-right: solid 1px #fff;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg); }
      a {
        padding: 16px 0;
        height: auto;
        line-height: 1.4; } } }
  #top-head.fixed #globalnav li a {
    padding: 16px 0;
    height: auto;
    line-height: 1.4; }
  #globalnav li a:hover {
    border: none; }
  // // footer
  #footer_box01 {
    background: #1B1B25;
    .inner {
      padding: 30px 0 0; }
    ul {
      flex-wrap: wrap;
      max-width: 560px;
      margin: 0 auto; }
    li {
      width: 32%;
      text-align: left;
      margin-bottom: 5px; } }
  #footer_box02 {
    padding: 40px 0 20px;
    #f_contact {
      margin-bottom: 30px; } } }

// screens smaller than 540----------------------------------------------------------
@media only screen and (max-width: 539px) {
  #footer_box01 {
    ul {
      max-width: 440px; }
    li {
      width: 49%; } } }

// screens smaller than 420----------------------------------------------------------
@media only screen and (max-width: 420px) {
  // // header
  header #h_menu {
    right: 60px; }
  #top-head #h_menu li {
    &:nth-child(2) {
      width: 40px; }
    &:before, &:after {
      content: none; } }
  // // globalnav
  #gnav_btn {
    width: 60px; } }
