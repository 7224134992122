html, body, div, span, object, iframe, e h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

* {
  margin: 0px;
  padding: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-size: 100%; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  td, th {
    vertical-align: top; } }

a {
  margin: 0;
  padding: 0;
  color: #333;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

img {
  border: 0px;
  vertical-align: top; }

ol, ul {
  list-style: none; }

caption, th {
  text-align: left; }

blockquote, q {
  quotes: none; }

blockquote {
  &:before, &:after {
    content: '';
    content: none; } }

q {
  &:before, &:after {
    content: '';
    content: none; } }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

html {
  overflow-y: scroll;
  overflow-x: hidden; }

input {
  border: none;
  background: none; }

/*smartphone */
/*------------------------------------------------------------------------------ */

html {
  -webkit-text-size-adjust: none; }

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3); }

/*base */
/*------------------------------------------------------------------------------ */

body {
  font-family: 'メイリオ','Meiryo','ＭＳ Ｐゴシック','MS PGothic','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro',Osaka,verdana,Sans-Serif;
  color: #FFF;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.2;
  /*word-break:break-all; */
  overflow: hidden; }

a {
  text-decoration: none;
  color: #CCFF00;
  &:hover {
    color: #CCFF00; }
  img {
    transition: 0.3s; } }

.bl-hot img, .zoom a img, .opa_a a {
  transition: 0.3s; }

a:hover img {
  opacity: 0.7; }

.zoom a:hover img {
  opacity: 1; }

.opa_a a:hover, .bl-hover img {
  opacity: 0.7; }

/*utility */
/*------------------------------------------------------------------------------ */

.m5 {
  margin-bottom: 5px; }

.m10 {
  margin-bottom: 10px; }

.m15 {
  margin-bottom: 15px; }

.m20 {
  margin-bottom: 20px; }

.m25 {
  margin-bottom: 25px; }

.m30 {
  margin-bottom: 30px; }

.m35 {
  margin-bottom: 35px; }

.m40 {
  margin-bottom: 40px; }

.m45 {
  margin-bottom: 45px; }

.m50 {
  margin-bottom: 50px; }

.m55 {
  margin-bottom: 55px; }

.m60 {
  margin-bottom: 60px; }

.m65 {
  margin-bottom: 65px; }

.m70 {
  margin-bottom: 70px; }

.m75 {
  margin-bottom: 75px; }

.m80 {
  margin-bottom: 80px; }

.m85 {
  margin-bottom: 85px; }

.m90 {
  margin-bottom: 90px; }

.m95 {
  margin-bottom: 95px; }

.m100 {
  margin-bottom: 100px; }

.mt5 {
  margin-top: 5px; }

.mt10 {
  margin-top: 10px; }

.mt15 {
  margin-top: 15px; }

.mt20 {
  margin-top: 20px; }

.mt25 {
  margin-top: 25px; }

.mt30 {
  margin-top: 30px; }

.mt35 {
  margin-top: 35px; }

.mt40 {
  margin-top: 40px; }

.mt45 {
  margin-top: 45px; }

.mt50 {
  margin-top: 50px; }

.mt55 {
  margin-top: 55px; }

.mt60 {
  margin-top: 60px; }

.mt65 {
  margin-top: 65px; }

.mt70 {
  margin-top: 70px; }

.mt75 {
  margin-top: 75px; }

.mt80 {
  margin-top: 80px; }

.mt85 {
  margin-top: 85px; }

.mt90 {
  margin-top: 90px; }

.mt95 {
  margin-top: 95px; }

.mt100 {
  margin-top: 100px; }

.ml5 {
  margin-left: 5px; }

.ml10 {
  margin-left: 10px; }

.ml15 {
  margin-left: 15px; }

.ml20 {
  margin-left: 20px; }

.ml25 {
  margin-left: 25px; }

.ml30 {
  margin-left: 30px; }

.ml35 {
  margin-left: 35px; }

.ml40 {
  margin-left: 40px; }

.ml45 {
  margin-left: 45px; }

.ml50 {
  margin-left: 50px; }

.mr5 {
  margin-right: 5px; }

.mr10 {
  margin-right: 10px; }

.mr15 {
  margin-right: 15px; }

.mr20 {
  margin-right: 20px; }

.mr25 {
  margin-right: 25px; }

.mr30 {
  margin-right: 30px; }

.mr35 {
  margin-right: 35px; }

.mr40 {
  margin-right: 40px; }

.mr45 {
  margin-right: 45px; }

.mr50 {
  margin-right: 50px; }

.pt5 {
  padding-top: 5px; }

.pt10 {
  padding-top: 10px; }

.pt15 {
  padding-top: 15px; }

.pt20 {
  padding-top: 20px; }

.pt25 {
  padding-top: 25px; }

.pt30 {
  padding-top: 30px; }

.pt35 {
  padding-top: 35px; }

.pt40 {
  padding-top: 40px; }

.pt45 {
  padding-top: 45px; }

.pt50 {
  padding-top: 50px; }

.pb5 {
  padding-bottom: 5px; }

.pb10 {
  padding-bottom: 10px; }

.pb15 {
  padding-bottom: 15px; }

.pb20 {
  padding-bottom: 20px; }

.pb25 {
  padding-bottom: 25px; }

.pb30 {
  padding-bottom: 30px; }

.pb35 {
  padding-bottom: 35px; }

.pb40 {
  padding-bottom: 40px; }

.pb45 {
  padding-bottom: 45px; }

.pb50 {
  padding-bottom: 50px; }

.pl5 {
  padding-left: 5px; }

.pl10 {
  padding-left: 10px; }

.pl15 {
  padding-left: 15px; }

.pl20 {
  padding-left: 20px; }

.pl25 {
  padding-left: 25px; }

.pl30 {
  padding-left: 30px; }

.pl35 {
  padding-left: 35px; }

.pl40 {
  padding-left: 40px; }

.pl45 {
  padding-left: 45px; }

.pl50 {
  padding-left: 50px; }

.pr5 {
  padding-right: 5px; }

.pr10 {
  padding-right: 10px; }

.pr15 {
  padding-right: 15px; }

.pr20 {
  padding-right: 20px; }

.pr25 {
  padding-right: 25px; }

.pr30 {
  padding-right: 30px; }

.pr35 {
  padding-right: 35px; }

.pr40 {
  padding-right: 40px; }

.pr45 {
  padding-right: 45px; }

.pr50 {
  padding-right: 50px; }

.mtb10 {
  margin: 10px 0; }

.mtb20 {
  margin: 20px 0; }

.mtb30 {
  margin: 30px 0; }

.ptb10 {
  padding: 10px 0; }

.ptb20 {
  padding: 20px 0; }

.ptb30 {
  padding: 30px 0; }

.flt {
  float: left; }

.flr {
  float: right; }

.txt_l, td.txt_l, .txt_l td {
  text-align: left; }

.txt_r, td.txt_r, .txt_r td {
  text-align: right; }

.txt_c, td.txt_c, .txt_c td {
  text-align: center; }

.valign_t, td.valign_t, .valign_t td {
  vertical-align: top; }

.valign_m, td.valign_m, .valign_m td {
  vertical-align: middle; }

.valign_b, td.valign_b, .valign_b td {
  vertical-align: bottom; }

.clear {
  clear: both; }

.clearfix:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

/*-------------------------------------------------------------------------- */

.pb img {
  border: #CCC solid 1px; }

.pb2 img {
  border: #CCC solid 1px;
  padding: 1px; }

.fs80 {
  font-size: 80%;
  line-height: 120%; }

.fs90 {
  font-size: 90%;
  line-height: 140%; }

.fs110 {
  font-size: 110%;
  line-height: 130%; }

.fs120 {
  font-size: 120%;
  line-height: 140%; }

.fs140 {
  font-size: 140%;
  line-height: 150%; }

.fs150 {
  font-size: 150%;
  line-height: 160%; }

.fs200 {
  font-size: 200%;
  line-height: 210%; }

.fs80b {
  font-size: 80%;
  line-height: 120%;
  font-weight: bold; }

.fs90b {
  font-size: 90%;
  line-height: 120%;
  font-weight: bold; }

.fs110b {
  font-size: 110%;
  line-height: 130%;
  font-weight: bold; }

.fs120b {
  font-size: 120%;
  line-height: 140%;
  font-weight: bold; }

.fs140b {
  font-size: 140%;
  line-height: 150%;
  font-weight: bold; }

.fs150b {
  font-size: 150%;
  line-height: 160%;
  font-weight: bold; }

.fs200b {
  font-size: 200%;
  line-height: 210%;
  font-weight: bold; }

.red {
  color: #fe0100; }

.red_bold {
  color: #fe0100;
  font-weight: bold; }

.orange {
  color: #FF9900; }

.orange_bold {
  color: #FF9900;
  font-weight: bold; }

.blue {
  color: #1664AF; }

.blue_bold {
  color: #1664AF;
  font-weight: bold; }

.type_none li {
  list-style: none; }

.type_disc li {
  list-style-type: disc;
  margin-left: 20px; }

.type_square li {
  list-style-type: square;
  margin-left: 17px; }

.type_decimal li {
  list-style-type: decimal;
  margin-left: 25px; }

/*------------------------------------------------------------------------------ */

/*==================================================================== */
/*responsive */

.img100 img {
  width: 100%;
  height: auto; }

.flt_pc {
  float: left; }

.flr_pc {
  float: right; }

.mt5_pc {
  margin-top: 5px; }

.mt10_pc {
  margin-top: 10px; }

.mt15_pc {
  margin-top: 15px; }

.mt20_pc {
  margin-top: 20px; }

.mt30_pc {
  margin-top: 30px; }

.mt40_pc {
  margin-top: 40px; }

.mt50_pc {
  margin-top: 50px; }

.mt55_pc {
  margin-top: 55px; }

.mt60_pc {
  margin-top: 60px; }

.mt65_pc {
  margin-top: 65px; }

.mt70_pc {
  margin-top: 70px; }

.mt75_pc {
  margin-top: 75px; }

.mt80_pc {
  margin-top: 80px; }

.mt85_pc {
  margin-top: 85px; }

.mt90_pc {
  margin-top: 90px; }

.m5_pc {
  margin-bottom: 5px; }

.m10_pc {
  margin-bottom: 10px; }

.m15_pc {
  margin-bottom: 15px; }

.m20_pc {
  margin-bottom: 20px; }

.m30_pc {
  margin-bottom: 30px; }

.m40_pc {
  margin-bottom: 40px; }

.m50_pc {
  margin-bottom: 50px; }

.m60_pc {
  margin-bottom: 60px; }

.m70_pc {
  margin-bottom: 70px; }

.m80_pc {
  margin-bottom: 80px; }

.m90_pc {
  margin-bottom: 90px; }

.m100_pc {
  margin-bottom: 100px; }

.m110_pc {
  margin-bottom: 110px; }

.m120_pc {
  margin-bottom: 120px; }

.mt10_sph {
  margin-top: 10px; }

.mt20_sph {
  margin-top: 20px; }

.mt30_sph {
  margin-top: 30px; }

.mt40_sph {
  margin-top: 40px; }

.mt50_sph {
  margin-top: 50px; }

.mt55_sph {
  margin-top: 55px; }

.mt60_sph {
  margin-top: 60px; }

.mt65_sph {
  margin-top: 65px; }

.mt70_sph {
  margin-top: 70px; }

.mt75_sph {
  margin-top: 75px; }

.mt80_sph {
  margin-top: 80px; }

.mt85_sph {
  margin-top: 85px; }

.mt90_sph {
  margin-top: 90px; }

.mt95_sph {
  margin-top: 95px; }

.mt100_sph {
  margin-top: 100px; }

.mt105_sph {
  margin-top: 105px; }

.m10_sph {
  margin-bottom: 10px; }

.m15_sph {
  margin-bottom: 15px; }

.m20_sph {
  margin-bottom: 20px; }

.m30_sph {
  margin-bottom: 30px; }

.m35_sph {
  margin-bottom: 35px; }

.m40_sph {
  margin-bottom: 40px; }

.m45_sph {
  margin-bottom: 45px; }

.m50_sph {
  margin-bottom: 50px; }

.m60_sph {
  margin-bottom: 60px; }

.m70_sph {
  margin-bottom: 70px; }

.m75_sph {
  margin-bottom: 75px; }

.m80_sph {
  margin-bottom: 80px; }

.m85_sph {
  margin-bottom: 85px; }

.m90_sph {
  margin-bottom: 90px; }

.m95_sph {
  margin-bottom: 95px; }

.m100_sph {
  margin-bottom: 100px; }

.m105_sph {
  margin-bottom: 105px; }

.m110_sph {
  margin-bottom: 110px; }

.m115_sph {
  margin-bottom: 115px; }

.m120_sph {
  margin-bottom: 120px; }

.m125_sph {
  margin-bottom: 125px; }

.m130_sph {
  margin-bottom: 130px; }

.pb30_sph {
  padding-bottom: 30px; }

.pb40_sph {
  padding-bottom: 40px; }

.pb50_sph {
  padding-bottom: 50px; }

.pb60_sph {
  padding-bottom: 60px; }

.pb70_sph {
  padding-bottom: 70px; }

.pb100_sph {
  padding-bottom: 100px; }

.br_sp {
  display: none; }

.w_auto {
  width: 980px;
  margin-left: auto;
  margin-right: auto; }

.txt_r_sph {
  text-align: right; }

/* ----------------------------------------------------------------------------------------------- */
@media only screen and (min-width: 641px) and (max-width: 1099px) {
  .w_auto {
    width: 94%; }
  .sp_non {
    img {
      width: 100%;
      height: auto; }
    &.desc {
      width: 100%;
      padding: 0 15%; } } }

/* ------------------------------------------------------------------------------------------------ */
@media only screen and (max-width: 640px) {
  .flt_pc, .flr_pc, .flt_pc_ipad, .flr_pc_ipad {
    float: none; }
  .mt5_ip {
    margin-top: 5px; }
  .mt10_ip {
    margin-top: 10px; }
  .mt15_ip {
    margin-top: 15px; }
  .mt20_ip {
    margin-top: 20px; }
  .mt30_ip {
    margin-top: 30px; }
  .mt40_ip {
    margin-top: 40px; }
  .mt50_ip {
    margin-top: 50px; }
  .mt60_ip {
    margin-top: 60px; }
  .m5_ip {
    margin-bottom: 5px; }
  .m10_ip {
    margin-bottom: 10px; }
  .m15_ip {
    margin-bottom: 15px; }
  .m20_ip {
    margin-bottom: 20px; }
  .m30_ip {
    margin-bottom: 30px; }
  .m40_ip {
    margin-bottom: 40px; }
  .m50_ip {
    margin-bottom: 50px; }
  .m60_ip {
    margin-bottom: 60px; }
  .mt5_pc, .mt10_pc, .mt15_pc, .mt20_pc, .mt30_pc, .mt40_pc, .mt50_pc, .mt60_pc {
    margin-top: 0; }
  .m5_pc, .m10_pc, .m15_pc, .m20_pc, .m30_pc, .m40_pc, .m50_pc, .m60_pc {
    margin-bottom: 0; }
  .mt10_sph {
    margin-top: 5px; }
  .mt20_sph {
    margin-top: 15px; }
  .mt30_sph {
    margin-top: 25px; }
  .mt40_sph {
    margin-top: 30px; }
  .mt50_sph {
    margin-top: 35px; }
  .mt55_sph {
    margin-top: 32px; }
  .mt60_sph {
    margin-top: 40px; }
  .mt65_sph {
    margin-top: 42px; }
  .mt70_sph {
    margin-top: 45px; }
  .mt75_sph {
    margin-top: 47px; }
  .mt80_sph {
    margin-top: 50px; }
  .mt85_sph {
    margin-top: 52px; }
  .mt90_sph {
    margin-top: 55px; }
  .mt95_sph {
    margin-top: 57px; }
  .mt100_sph {
    margin-top: 60px; }
  .mt105_sph {
    margin-top: 62px; }
  .m10_sph {
    margin-bottom: 5px; }
  .m15_sph {
    margin-bottom: 7px; }
  .m20_sph {
    margin-bottom: 15px; }
  .m30_sph {
    margin-bottom: 20px; }
  .m35_sph {
    margin-bottom: 25px; }
  .m40_sph, .m45_sph {
    margin-bottom: 30px; }
  .m50_sph {
    margin-bottom: 38px; }
  .m60_sph, .m70_sph {
    margin-bottom: 40px; }
  .m75_sph, .m80_sph, .m85_sph {
    margin-bottom: 45px; }
  .m90_sph, .m95_sph {
    margin-bottom: 50px; }
  .m100_sph {
    margin-bottom: 60px; }
  .m105_sph {
    margin-bottom: 65px; }
  .m110_sph {
    margin-bottom: 75px; }
  .m115_sph {
    margin-bottom: 77px; }
  .m120_sph {
    margin-bottom: 80px; }
  .m125_sph {
    margin-bottom: 82px; }
  .pb30_sph {
    padding-bottom: 15px; }
  .pb40_sph {
    padding-bottom: 20px; }
  .pb50_sph {
    padding-bottom: 25px; }
  .pb60_sph {
    padding-bottom: 30px; }
  .pb70_sph {
    padding-bottom: 40px; }
  .pb100_sph {
    padding-bottom: 60px; }
  .br_sp {
    display: block; }
  .sp_non img {
    display: none; } }
